import Vue from 'vue';
import { BootstrapVue, IconsPlugin, ModalPlugin, ToastPlugin } from 'bootstrap-vue';
import VueCompositionAPI from '@vue/composition-api';

import { VeeValidate } from '@validations';
import i18n from '@/libs/i18n'
import router from '@/router';
import store from '@/store';
import App from './App.vue';

// Global Components
import './global-components';

// Global Methods
import './global-methods';

// 3rd party plugins
import '@/libs/portal-vue';
import '@/libs/toastification';
import '@/libs/acl';
import axios from '@/libs/axios';
import UUID from 'vue-uuid';

// Tilda settings
import localeConfig from '~/libs/localeConfig';

// BSV Plugin Registration
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

//axios
//Vue.use(axios);

//UUID
Vue.use(UUID);

// import core styles
require('@core/scss/core.scss');

// import assets styles
require('@/assets/scss/style.scss');

require('@core/scss/vue/libs/vue-wizard.scss');
require('@core/scss/vue/libs/vue-select.scss');

//set locale
const defaultLocale = localeConfig.default || 'en';
i18n.locale = defaultLocale;
VeeValidate.localize(defaultLocale);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app');
