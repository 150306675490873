<template>
    <b-overlay :show="loading">
        <template #overlay>
            <custom-loader/>
        </template>
        <b-row v-if="!showPreview">
            <b-col cols="12">
                <b-button class="float-right" variant="outline-primary" @click="() => this.showPreview = true">Prikaži predogled</b-button>
            </b-col>
        </b-row>
        <b-row v-if="showPreview && files.length > 0">
            <b-col cols="12">
                <b-button class="float-right" variant="outline-primary" @click="() => this.showPreview = false">Skrij predogled</b-button>
            </b-col>
            <b-col cols="12">
                <value-select :initial-value="file"
                              :options="files"
                              selected-label="name"
                              label="Dokumenti"
                              placeholder="Izberi dokument"
                              :clearable="true"
                              @value-change="v => this.file = v"></value-select>
            </b-col>
            <b-col v-if="file !== null" :key="file.key" cols="12">
                <b-col v-if="(file.rawFile.type || '').toLowerCase().includes('pdf')" cols="12">
                    <div class="pdf-header">
                        <template v-if="pdfLoading">
                            Nalagam...
                        </template>

                        <template v-else>
                            <span v-if="pdfShowAllPages">{{ pdfPageCount }} stran/i</span>

                            <span v-else>
                            <b-button :disabled="pdfPage <= 1" variant="outline-primary" @click="pdfPage--">❮</b-button>
                            {{ pdfPage }} / {{ pdfPageCount }}
                            <b-button :disabled="pdfPage >= pdfPageCount" variant="outline-primary" @click="pdfPage++">❯</b-button>
                        </span>

                            <b-form-checkbox v-model="pdfShowAllPages" class="float-right" switch>Vse strani</b-form-checkbox>
                        </template>
                    </div>

                    <div class="pdf-content">
                        <vue-pdf-embed
                            ref="pdfRef"
                            :page="pdfPage"
                            :source="getPdf(file.rawFile)"
                            @rendered="handleDocumentRender"
                        />
                    </div>
                </b-col>
                <b-col v-else cols="12">
                    <img ref="fileImg" :src="getImg(file.rawFile)"  alt="" style="max-width:90%;"/>
                </b-col>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col cols="12">
                Nabor datotek je prazen
            </b-col>
        </b-row>
    </b-overlay>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';

export default {
    components: {VuePdfEmbed},

    props: {
        propFiles: {
            type: Array,
            default: [],
        },
    },

    data() {
        return {
            showPreview: true,
            loading: true,
            files: [],
            file: null,

            pdfLoading: true,
            pdfPage: null,
            pdfPageCount: 1,
            pdfShowAllPages: true,
        };
    },
    async mounted() {
        this.files = [...this.propFiles]
            .filter(pf => !!pf)
            .map(pf => {
                return {
                    name: pf.name || 'Datoteka',
                    key: this.$uuid.v1(),
                    rawFile: pf,
                };
            });
        if (this.files.length > 0) this.file = this.files[0];
        this.loading = false;
    },
    methods: {
        getPdf(file) {
            console.log(file);
            return window.URL.createObjectURL(file);
        },
        getImg(file) {
            console.log(file);
            return window.URL.createObjectURL(file);
        },
        handleDocumentRender() {
            this.pdfPageCount = this.$refs.pdfRef.pageCount;
            this.pdfLoading = false;
        },
    },
    watch: {
        pdfShowAllPages() {
            this.pdfPage = this.pdfShowAllPages ? null : 1
        },
    },
};
</script>

<style scoped>
.pdf-header {
    padding: 16px;
    box-shadow: #b4b7bd;
    background-color: #b4b7bd;
    color: #383636;
}
.pdf-content {
    padding: 24px 16px;
}
</style>
