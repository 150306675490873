import * as veeValidate from 'vee-validate';

import * as rules from 'vee-validate/dist/rules';
import en from 'vee-validate/dist/locale/en.json';
import sl from './locale/sl.json';

// eslint-disable-next-line object-curly-newline
import { validatorCreditCard, validatorPassword, validatorPositive, validatorUrlValidator } from './validators';

// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////

Object.keys(rules).forEach(rule => {
    veeValidate.extend(rule, {
        ...rules[rule], // copies rule configuration
        message: en[rule], // assign message
    });
});

export const positive = veeValidate.extend('positive', {
    validate: validatorPositive,
    message: 'Please enter positive number!',
});

export const credit = veeValidate.extend('credit-card', {
    validate: validatorCreditCard,
    message: 'It is not valid credit card!',
});

export const password = veeValidate.extend('password', {
    validate: validatorPassword,
    message: 'Your {_field_} must contain at least one uppercase, one lowercase, one special character and one digit',
});

export const url = veeValidate.extend('url', {
    validate: validatorUrlValidator,
    message: 'URL is invalid',
});

export const emso = veeValidate.extend('emso',v => {
    if(!(/^\d{13}$/.test(v))) return 'Emso mora vsebovati 13 numeričnih znakov.';

    const c1 = Number(v.substring(0,1)) * 7;
    const c2 = Number(v.substring(1,2)) * 6;
    const c3 = Number(v.substring(2,3)) * 5;
    const c4 = Number(v.substring(3,4)) * 4;
    const c5 = Number(v.substring(4,5)) * 3;
    const c6 = Number(v.substring(5,6)) * 2;
    const c7 = Number(v.substring(6,7)) * 7;
    const c8 = Number(v.substring(7,8)) * 6;
    const c9 = Number(v.substring(8,9)) * 5;
    const c10 = Number(v.substring(9,10)) * 4;
    const c11 = Number(v.substring(10,11)) * 3;
    const c12 = Number(v.substring(11,12)) * 2;
    const c13 = Number(v.substring(12,13));

    const sum = c1+c2+c3+c4+c5+c6+c7+c8+c9+c10+c11+c12;
    const division = sum % 11;

    if(division === 0) {
        if(0 === c13){
            return true;
        }
        else {
            return 'Emso ni veljaven.';
        }
    }
    else if ((11 - division) === c13){
        return true;
    }
    else {
        return 'Emso ni veljaven.';
    }
});

// Install English and Slovene localizations.
veeValidate.localize({
    sl: {
        messages: sl.messages,
        names: {
            email: 'Email',
            password: 'Geslo',
        },
        fields: {
            password: {
                min: '{_field_} je prekratko.',
            },
        },
    },
    en: {
        messages: en.messages,
        names: {
            email: 'Email',
            password: 'Password',
        },
        fields: {
            password: {
                min: '{_field_} is too short, you want to get hacked?',
            },
        },
    },
});

export const ValidationObserver = veeValidate.ValidationObserver;

export const ValidationProvider = veeValidate.ValidationProvider;

export const validate = veeValidate.validate;

export const VeeValidate = veeValidate;


/*import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/en.json';

Object.keys(rules).forEach(rule => {
    extend(rule, {
        ...rules[rule], // copies rule configuration
        message: messages[rule] // assign message
    });
});*/
