import axios from '@/libs/axios';

const apiEndpoint = '/auth/competition-parts';

export default {
    namespaced: true,
    state: {
        competitionParts: [],
    },
    getters: {},
    mutations: {
        SET_COMPETITIONS_PARTS(state, competitionParts) {
            state.competitionParts = competitionParts;
        },
    },
    actions: {
        async getAll (context) {
            if(context.state.competitionParts.length === 0) {
                try {
                    const competitionParts = (await axios.get(apiEndpoint+'?cacheBust='+(Date.now()).toString())).data.data;
                    context.commit('SET_COMPETITIONS_PARTS', competitionParts);
                }
                catch (e) {
                    console.log(e);
                    context.commit('SET_COMPETITIONS_PARTS', []);
                }
            }
            return context.state.competitionParts;
        },
    },
}
