import axios from '@/libs/axios';

const apiEndpoint = '/auth/competitions';

export default {
    namespaced: true,
    state: {
        competitions: [],
    },
    getters: {
        async all(state) {
            if(state.competitions.length === 0) {
                try {
                    state.competitions = (await axios.get(apiEndpoint+'?cacheBust='+(Date.now()).toString())).data.data;
                }
                catch (e) {
                    console.log(e);
                    state.competitions = [];
                }
            }
            return state.competitions;
        },
    },
    mutations: {
        SET_COMPETITIONS(state, competitions) {
            state.competitions = competitions;
        },
    },
    actions: {
        async forceRefresh (context) {
            try {
                const competitions = (await axios.get(apiEndpoint+'?cacheBust='+(Date.now()).toString())).data.data;
                context.commit('SET_COMPETITIONS', competitions);
            }
            catch (e) {
                console.log(e);
                context.commit('SET_COMPETITIONS', []);
            }
        },
        async getById (context, {bvToast,id}) {
            try {
                return (await axios.get(apiEndpoint+'/'+id)).data.data;
            }
            catch (e) {
                console.log(e);
                bvToast.toast('Prosimo, da kontaktirate administratorja aplikacije.', {
                    title: 'Napaka',
                    variant: 'warning',
                    solid: true,
                    toaster: 'b-toaster-bottom-right',
                });
                return null;
            }
        },
        async getTeams (context, {id}) {
            try {
                return (await axios.get(apiEndpoint+'/'+id+'/teams')).data.data || [];
            }
            catch (e) {
                console.log(e);
                return [];
            }
        },
        async update (context, {bvToast,id,data}) {
            try {
                return (await axios.put(apiEndpoint+'/'+id, data)).data.data;
            }
            catch (e) {
                bvToast.toast('Prosimo, da kontaktirate administratorja aplikacije.', {
                    title: 'Napaka',
                    variant: 'warning',
                    solid: true,
                    toaster: 'b-toaster-bottom-right',
                });
                return null;
            }
        },
    },
}
