import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import cleave from 'vue-cleave-component';
//fa
import {library} from '@fortawesome/fontawesome-svg-core'
import { faFontAwesome, fab} from '@fortawesome/free-brands-svg-icons'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText} from '@fortawesome/vue-fontawesome'

//owms components
import Loader from '@/owms/components/loader/Loader.vue'
import BooleanCheckbox from '@/owms/components/checkbox/BooleanCheckbox';
import TextInput from '@/owms/components/input/TextInput';
import TextAreaInput from '@/owms/components/input/TextAreaInput';
import NumberInput from '@/owms/components/input/NumberInput';
import ValueSelect from '@/owms/components/select/ValueSelect';
import DateSelect from '@/owms/components/date/DateSelect';
import TimeSelect from '@/owms/components/date/TimeSelect';
import BaseTable from '@/owms/components/table/BaseTable';
import BaseForm from '@/owms/components/form/BaseForm';
import AutocompletePlaces from '@/owms/components/google/AutocompletePlaces';
import Cropper from '@/owms/components/image/Cropper';
import Preview from '@/owms/components/file/Preview';
import PasswordInput from "@/owms/components/input/PasswordInput";
import PreviewClient from "@/owms/components/file/PreviewClient";


Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component('cleave', cleave);
//fa
library.add(faFontAwesome)
library.add(fas, fab);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);

//owms components
Vue.component('custom-loader', Loader)
Vue.component('boolean-checkbox', BooleanCheckbox);
Vue.component('password-input', PasswordInput);
Vue.component('text-input', TextInput);
Vue.component('text-area-input', TextAreaInput);
Vue.component('number-input', NumberInput);
Vue.component('value-select', ValueSelect);
Vue.component('date-select', DateSelect);
Vue.component('time-select', TimeSelect);
Vue.component('base-table', BaseTable);
Vue.component('base-form', BaseForm);
Vue.component('google-autocomplete-places', AutocompletePlaces);
Vue.component('image-cropper', Cropper);
Vue.component('file-preview', Preview);
Vue.component('file-preview-client', PreviewClient);
