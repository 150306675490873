function toastSuccess(title = 'Obvestilo') {
    return {
        title: title,
        variant: 'success',
        solid: true,
        toaster: 'b-toaster-bottom-right',
    };
}

function toastWarning(title = 'Opozorilo') {
    return {
        title: title,
        variant: 'warning',
        solid: true,
        toaster: 'b-toaster-bottom-right',
    };
}

function toastError(title = 'Napaka') {
    return {
        title: title,
        variant: 'danger',
        solid: true,
        toaster: 'b-toaster-bottom-right',
    };
}

export {
    toastSuccess,
    toastWarning,
    toastError,
};
