<template>
    <b-form-group :label="label">
        <b-input-group>
            <cleave v-model="cleaveValue" :class="state === false || stateTwo === false ? 'form-control error': 'form-control'"
                    :raw="false" :options="dateFormat" :placeholder="placeholder" :disabled="disabled" />
            <b-input-group-append>
                <b-form-datepicker v-model="pickerValue" show-decade-nav button-only right label-help="" label-no-date-selected=""
                                   dropright size="sm" locale="en" aria-controls="example-input" :disabled="disabled" />
            </b-input-group-append>
            <b-form-invalid-feedback :state="state">
                {{feedback}}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :state="stateTwo">
                {{feedbackTwo}}
            </b-form-invalid-feedback>
        </b-input-group>
    </b-form-group>
</template>

<script>
export default {
    props: {
        label: { type: String, default: '' },
        feedback: { type: String, default: '' },
        state: { type: Boolean, default: true },
        feedbackTwo: { type: String, default: '' },
        stateTwo: { type: Boolean, default: true },
        initialValue: { type: String, default: '' },
        disabled: { type: Boolean, default: false },
        placeholder: { type: String, default: 'DD.MM.YYYY' },
    },

    data() {
        return {
            value: '',
            cleaveValue: null,
            dateFormat: {
                date: true,
                delimiter: '.',
                datePattern: ['d', 'm', 'Y'],
                onValueChanged: (e) => {this.onCleaveValueChanged(e);},
            },
        }
    },

    mounted() {
        if((this.initialValue || null) !== null) {
            let tmpDateYMD = this.initialValue.split('-');
            this.cleaveValue = tmpDateYMD[2]+'.'+tmpDateYMD[1]+'.'+tmpDateYMD[0];
            this.value = this.initialValue;
        }
    },

    computed: {
        pickerValue: {
            set: function(val) {
                let tmpDateYMD = val.split('-');
                this.cleaveValue = tmpDateYMD[2]+'.'+tmpDateYMD[1]+'.'+tmpDateYMD[0];
                this.value = val;
            },
            get: function() {
                return this.value;
            },
        },
    },

    methods: {
        onCleaveValueChanged(e) {
            if(e.target.rawValue.length === 8) {
                let tmpDateDMY = e.target.value.split('.');
                let res = tmpDateDMY[2] +'-'+ tmpDateDMY[1]  +'-'+ tmpDateDMY[0];
                this.value = res;

                this.$emit('value-change', res);
            }
            else {
                this.value = '';
                this.$emit('value-change', '');
            }
        },
    },
}
</script>

<style scoped>
.error {
    border-color: #ea5455
}
</style>
