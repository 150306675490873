import {isNumber} from '@/owms/utils/number';

function storeBTabStorage(s) {
    sessionStorage.setItem('base-table-storage', JSON.stringify(s));
}

export default {
    namespaced: true,
    state: {
        storage: JSON.parse(
            sessionStorage.getItem('base-table-storage') || '{}'
        ),
    },
    getters: {},
    mutations: {
        INIT_B_TAB(state, {key, filter, perPage}) {
            state.storage[key] = state.storage[key] || {initialized: false};
            if(!state.storage[key].initialized) {
                state.storage[key].initialized = true;
                this.commit('baseTable/SET_B_TAB_FILTER', {key: key, filter: filter});
                this.commit('baseTable/SET_B_TAB_PAGINATION', {key: key, pagination: {totalRows: 10, perPage: perPage, currentPage: 1}});

                storeBTabStorage(state.storage);
            }
        },
        SET_B_TAB_FILTER(state, {key, filter}) {
            state.storage[key] = state.storage[key] || {};

            let f = {};
            if(Object.keys(filter||{}).length > 0) {
                for (const k in filter) {
                    const v = filter[k] || null;

                    if(v === null || v === '')
                        f[k] = null;
                    if (Array.isArray(v) && v.length > 0)
                        f[k] = v.map(x => (String(x)).trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
                    else if (isNumber(v))
                        f[k] = Number(v);
                    else if (filter[k] !== '' && filter[k] !== null)
                        f[k] = (String(filter[k])).trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                }
            }

            state.storage[key]['filter'] = Object.keys(f).length > 0 ? f : null;
            storeBTabStorage(state.storage);
        },
        SET_B_TAB_SORT(state, {key, sort}) {
            state.storage[key] = state.storage[key] || {};

            let s = {};
            if((sort||'') !== '' && (sort.sortBy||'') !== '') {
                s['sortBy'] = sort.sortBy;
                s['sortDesc'] = sort.sortDesc;
            }

            state.storage[key].sort = Object.keys(s).length > 0 ? s : null;
            storeBTabStorage(state.storage);
        },
        SET_B_TAB_PAGINATION(state, {key, pagination}) {
            state.storage[key] = state.storage[key] || {};
            state.storage[key].pagination = state.storage[key].pagination || {totalRows: 10, perPage: 10, currentPage: 1};

            state.storage[key].pagination = pagination;
            storeBTabStorage(state.storage);
        },
    },
    actions: {
        isBTabInitialized(context, key) {
            const s = context.state.storage[key] || {};
            return s.hasOwnProperty('initialized') && s.initialized;
        },
        getBTabFilter(context, key) {
            const s = context.state.storage[key] || {};
            return s.filter||null;
        },
        getBTabSort(context, key) {
            const s = context.state.storage[key] || {};
            return s.sort||null;
        },
        getBTabPagination(context, key) {
            const s = context.state.storage[key] || {};
            if((s.pagination||null) !== null) return s.pagination;
            return {totalRows: 10, perPage: 10, currentPage: 1};
        },
    },
}
