import { Subjects } from './subjects';
import { Actions } from './actions';
import { Roles } from './roles';

export const roleAbility = {
    [Roles.Administrator]: [
        { action: Actions.manage, subject: Subjects.Auth },

        { action: Actions.manage, subject: Subjects.AdminScope },

        { action: Actions.manage, subject: Subjects.Club },
        { action: Actions.manage, subject: Subjects.Competition },
        { action: Actions.manage, subject: Subjects.Game },
        { action: Actions.manage, subject: Subjects.Jersey },
        { action: Actions.manage, subject: Subjects.PlayerTeam },
        { action: Actions.manage, subject: Subjects.Registration },
        { action: Actions.manage, subject: Subjects.RegistrationComity },
        { action: Actions.manage, subject: Subjects.Team },
        { action: Actions.manage, subject: Subjects.Tournament },
        { action: Actions.manage, subject: Subjects.User },
    ],
    [Roles.Klub]: [
        { action: Actions.read, subject: Subjects.Auth },

        { action: Actions.manage, subject: Subjects.ClubScope },

        { action: Actions.create, subject: Subjects.Registration },
        { action: Actions.read, subject: Subjects.Registration },
        { action: Actions.update, subject: Subjects.Registration },

        { action: Actions.manage, subject: Subjects.MyTeam },
        { action: Actions.manage, subject: Subjects.MyClub },
        { action: Actions.manage, subject: Subjects.PlayerTeam },

        { action: Actions.read, subject: Subjects.Game },
        { action: Actions.read, subject: Subjects.Jersey },

        { action: Actions.read, subject: Subjects.Tournament },
    ],
    [Roles.Sodnik]: [
        { action: Actions.read, subject: Subjects.Auth },

        { action: Actions.manage, subject: Subjects.RefereeScope },

        { action: Actions.read, subject: Subjects.Game },
        { action: Actions.update, subject: Subjects.Game },
        { action: Actions.read, subject: Subjects.Jersey },
    ],
};
