import store from '@/store';
import { roleAbility } from '~/libs/acl/roleAbility';

const role = store.getters['user/getRole'];
const abilities = role ? [...roleAbility[role]] : [];

export const guestAbilities = [
    {
        action: 'read',
        subject: 'Guest',
    },
];

export const initialAbilities = [
    ...guestAbilities,
    ...abilities,
];

