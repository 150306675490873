import axios from '@/libs/axios';

const apiEndpoint = '/auth/teams';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        async getTeam ({}, {id}) {
            try {
                return (await axios.get(apiEndpoint+'/'+id)).data.data || null;
            }
            catch (e) {
                console.log(e);
                return null;
            }
        },
        async getTeamRegistration ({}, id) {
            try {
                return (await axios.get(`/auth/team-registrations/${id}`)).data.data || null;
            }
            catch (e) {
                console.log(e);
                return null;
            }
        },
        async teamInformation ({}, teamId) {
            try {
                return (await axios.get(`/auth/team-information/${teamId}`)).data.data || [];
            }
            catch (e) {
                console.log(e);
                return [];
            }
        },
        async storeInformation ({}, data) {
            try {
                return (await axios.post(`/auth/team-information/update-or-create/${data.teamId}`, data)).data.data || null;
            }
            catch (e) {
                console.log(e);
                throw e;
            }
        },
        async confirmInformation ({}, data) {
            try {
                return (await axios.post(`/auth/team-information/confirm/${data.id}`, data)).data.data || null;
            }
            catch (e) {
                console.log(e);
                throw e;
            }
        },
    },
}
