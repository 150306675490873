import Vue from 'vue'
import { formatDate, formatDateTime } from '@/owms/utils/date';
import { objectValueByPath, objectRemoveNullAttr } from '@/owms/utils/object';
import { isNumber } from '@/owms/utils/number';
import {
    toastSuccess,
    toastWarning,
    toastError,
} from '@/owms/utils/toast';
import {stringCompare} from '@/owms/utils/comperator';

Vue.mixin({
    methods: {
        formatDate: formatDate,
        formatDateTime: formatDateTime,
        objectValueByPath: objectValueByPath,
        objectRemoveNullAttr: objectRemoveNullAttr,
        isNumber: isNumber,
        toastSuccess: toastSuccess,
        toastWarning: toastWarning,
        toastError: toastError,
        stringCompare: stringCompare,
    },
})

