function formatDate(value) {
    if (value === null) return null;

    try {
        return new Intl.DateTimeFormat('de-DE').format(new Date(value));
    }
    catch (e) {
        console.error(e);
        return null;
    }
}

function formatDateTime(value) {
    if (value === null) return null;

    const options = {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric',
        hour12: false,
    };

    try {
        return new Intl.DateTimeFormat('de-DE', options).format(new Date(value));
    }
    catch (e) {
        console.error(e);
        return null;
    }
}

export { formatDate, formatDateTime };
