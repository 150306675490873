<template>
    <b-form-group :label="label" :label-for="uuid">
        <b-input-group :size="size">
            <b-form-checkbox v-model="value"
                             :id="uuid"
                             :value="true"
                             :unchecked-value="false"
                             switch
                             :disabled="disabled"
            ></b-form-checkbox>
            <b-form-invalid-feedback>{{feedback}}</b-form-invalid-feedback>
        </b-input-group>
    </b-form-group>
</template>

<script>
export default {
    name: 'BooleanCheckbox',
    props: {
        label: { type: String, default: null, },
        state: { type: Boolean, default: null, },
        feedback: { type: String, default: null, },
        initialValue: { type: Boolean, default: false, },
        disabled: { type: Boolean,default: false, },
        size: { type: String, default: 'md', },
    },
    data() {
        return {
            uuid: this.$uuid.v1(),
        };
    },
    computed: {
        value: {
            get: function() {
                return this.initialValue;
            },
            set: function(newValue) {
                this.$emit('value-change', Boolean(newValue));
            },
        },
    },
}
</script>
