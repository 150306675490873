import router from '@/router';
import store from '@/store';
import jwtService from '@/auth/jwt/useJwt';
import { guestAbilities } from '~/libs/acl/abilityConfig';
import ability from '@/libs/acl/ability';

export default {
    // Endpoints
    loginEndpoint: '/login',
    registerEndpoint: '/not-implemented/register',
    refreshEndpoint: '/refresh',
    logoutEndpoint: '/auth/logout',

    // This will be prefixed in authorization header with token
    // e.g. Authorization: Bearer <token>
    tokenType: 'Bearer',

    // Value of this property will be used as key to store JWT token in storage
    storageTokenKeyName: 'accessToken',
    storageRefreshTokenKeyName: 'refreshToken',

    // TODO add as "enum" Storage type
    storageType: 'localStorage',

    // When refresh fails
    onRefreshError: (e) => {
        jwtService.deleteToken();
        jwtService.deleteRefreshToken();
        store.commit('user/DELETE_USER_INFO');
        ability.update([...guestAbilities]);
        router.push('login');
    }
};
