import axios from '@/libs/axios';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        async getParentClub ({}, playerId) {
            try {
                return (await axios.get('/auth/players/' + playerId + '/parent-club')).data || null;
            }
            catch (e) {
                console.log(e);
                return null;
            }
        },
        async getBasicInfo ({}, playerId) {
            try {
                return (await axios.get('/auth/players/' + playerId + '/basic-info')).data.data || null;
            }
            catch (e) {
                console.log(e);
                return null;
            }
        },
        async getPlayerStatus ({}, playerId) {
            try {
                return (await axios.get('/auth/players/' + playerId + '/status')).data.data.statusId || null;
            }
            catch (e) {
                console.log(e);
                return null;
            }
        },
        async getPlayerActiveSeasonTeams ({}, playerId) {
            try {
                return (await axios.get('/auth/players/' + playerId + '/active-teams')).data.data || [];
            }
            catch (e) {
                console.log(e);
                return [];
            }
        },
        async getPlayerAllTeams ({}, playerId) {
            try {
                return (await axios.get('/auth/players/' + playerId + '/all-teams')).data.data || [];
            }
            catch (e) {
                console.log(e);
                return [];
            }
        },
        async getTransfers ({}, playerId) {
            try {
                return (await axios.get('/auth/players/' + playerId + '/transfers')).data.data || [];
            }
            catch (e) {
                console.log(e);
                return [];
            }
        },
        async confirmPicture({}, {playerId, fileId, editImage, image}) {
            try {
                const request = new FormData();
                request.append('fileId', fileId);
                if(editImage) request.append('image', image);

                return (await axios.post('/auth/players/' + playerId + '/picture/confirm', request, {headers: {'Content-Type': 'multipart/form-data'}})).data.data || null;
            }
            catch (e) {
                console.log(e);
                throw e;
            }
        },
        async rejectPicture({}, {playerId, fileId}) {
            try {
                return (await axios.patch('/auth/players/' + playerId + '/picture/reject', {fileId:fileId})).data.data || null;
            }
            catch (e) {
                console.log(e);
                throw e;
            }
        },
        async updateInfo({}, {id, data}) {
            try {
                return (await axios.put(`/auth/players/${id}`, data)).data.data || null;
            }
            catch (e) {
                console.log(e);
                return null;
            }
        },
    },
}
