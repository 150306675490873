import ActivityBasedTimer from '@/owms/versionManager/activityBasedTimer';
import axiosIns from '@/libs/axios';

const url = process.env.VUE_APP_VERSION_URL || '';
const defaultVersion = '1665654786459';
const versionStorageKey = 'FeOwmsCoreVersion1665654786459';

const versioningTimer = ActivityBasedTimer();

versioningTimer.setInterval({
    async callback() {
        const response = await axiosIns.get(url+'?cacheBust='+(Date.now()).toString());
        const index = document.createElement('html');
        index.innerHTML = response.data;

        for(const c of index.firstChild.children) {
            if(c.name === 'owms:version') {
                const currentVersion = String(getVersion()).toString();
                const indexVersion = String(c.content).toString();

                if(currentVersion !== indexVersion) {
                    if (confirm("Aplikacija je bila posodobljena. Prosimo, da osvežite stran. (gumb OK)") === true) {
                        setVersion(indexVersion);
                        clearStorage();
                        window.location.reload();
                    }
                    else {
                        alert('Prosimo, da v najkrajšem možnem času, osvežite stran.');
                    }
                }
            }
        }
    },
    // Normal interval is once every 5 minutes
    interval: 1000 * 60 * 5,
    // Forced interval is once per 12 hours
    forcedInterval: 1000 * 60 * 60 * 12,
});

function getVersion() {
    return localStorage.getItem(versionStorageKey) || defaultVersion;
}

function setVersion(v) {
    return localStorage.setItem(versionStorageKey, v);
}

function clearStorage() {
    const keysToKeep = ['accessToken', 'refreshToken', 'firstname', 'lastname', 'email', 'role', 'userData', versionStorageKey];
    const savedItems = {};

    keysToKeep.forEach(key => {
        const value = localStorage.getItem(key);
        if (value !== null) {
            savedItems[key] = value;
        }
    });

    localStorage.clear();
    Object.keys(savedItems).forEach(key => {
        localStorage.setItem(key, savedItems[key]);
    });

    sessionStorage.clear();
}

export { getVersion, setVersion, versioningTimer };
