import axios from '@/libs/axios';

const apiEndpoint = '/auth/sport-halls';

export default {
    namespaced: true,
    state: {
        sportHalls: [],
        refreshData: true,
    },
    getters: {},
    mutations: {
        SET_REFRESH_DATA(state, refreshData) {
            state.refreshData = refreshData;
        },
        SET_SPORT_HALLS(state, sportHalls) {
            state.sportHalls = sportHalls;
        },
    },
    actions: {
        async getAll (context) {
            if(context.state.sportHalls.length === 0 || context.state.refreshData) {
                try {
                    const sportHalls = (await axios.get(apiEndpoint+'?cacheBust='+(Date.now()).toString())).data.data;
                    context.commit('SET_SPORT_HALLS', sportHalls);
                    context.commit('SET_REFRESH_DATA', false);
                }
                catch (e) {
                    console.log(e);
                    context.commit('SET_SPORT_HALLS', []);
                }
            }
            return context.state.sportHalls;
        },
    },
}
