<template>
    <b-form-group :label="label">
        <v-select
            v-model="value"
            :label="selectedLabel"
            :options="options"
            :class="!state ? 'error' : ''"
            :clearable="clearable"
            :reduce="x => reduce(x)"
            :multiple="multiple"
            :disabled="disabled"
            :placeholder="placeholder"
            :append-to-body="appendToBody"
        >
            <template #open-indicator="{ attributes }">
                <span v-bind="attributes"><font-awesome-icon icon="chevron-down"/></span>
            </template>
        </v-select>
        <b-form-invalid-feedback :state="state">{{ feedback }}</b-form-invalid-feedback>
    </b-form-group>
</template>

<script>
import vSelect from 'vue-select';

export default {
    name: 'ValueSelect',
    components: {vSelect},
    props: {
        label: {type: String, default: null},
        placeholder: {type: String, default: null},
        state: {type: Boolean, default: true},
        feedback: {type: String, default: null},
        initialValue: {default: null},
        size: {type: String, default: 'md'},
        options: {type: Array},
        selectedLabel: {type: String, default: 'label'},
        clearable: {type: Boolean, default: false},
        doReduce: {type: Boolean, default: false},
        reduceBy: {type: String},
        multiple: {type: Boolean, default: false},
        disabled: {type: Boolean, default: false },
        appendToBody: {type: Boolean, default: false },
    },
    data() {
        return {};
    },
    computed: {
        value: {
            get: function () {
                return this.initialValue;
            },
            set: function (newValue) {
                this.$emit('value-change', newValue);
            },
        },
    },
    methods: {
        reduce(x) {
            if (this.doReduce)
                return x[this.reduceBy];
            return x;
        },
    }
}
</script>
