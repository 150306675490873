<template>
    <b-form-group :label="label" :label-for="uuid">
        <b-input-group :size="size">
            <b-form-input v-model="value"
                          :state="state"
                          :id="uuid"
                          :disabled="disabled"
                          :debounce="debounce"
                          :placeholder="placeholder"
                          type="password" />
            <b-form-invalid-feedback>{{feedback}}</b-form-invalid-feedback>
        </b-input-group>
    </b-form-group>
</template>

<script>
export default {
    name: 'PasswordInput',
    props: {
        label: { type: String, default: null, },
        state: { type: Boolean, default: null, },
        feedback: { type: String, default: null, },
        initialValue: { default: null, },
        disabled: { type: Boolean,default: false, },
        size: { type: String, default: 'md', },
        debounce: { type: Number, default: 0, },
        placeholder: { type: String, default: null, },
    },
    data() {
        return {
            uuid: this.$uuid.v1(),
        };
    },
    computed: {
        value: {
            get: function() {
                return this.initialValue;
            },
            set: function(newValue) {
                this.$emit('value-change', newValue);
            },
        },
    },
}
</script>
