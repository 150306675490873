import axios from '@/libs/axios';

const apiEndpoint = '/auth/referees';

export default {
    namespaced: true,
    state: {
        types: [],
    },
    getters: {},
    mutations: {
        SET_TYPES(state, types) {
            state.types = types;
        },
    },
    actions: {
        async getReferees ({}) {
            try {
                return (await axios.get(apiEndpoint+'?cacheBust='+(Date.now()).toString())).data.data || [];
            }
            catch (e) {
                console.log(e);
                return [];
            }
        },
        async getTypes (context) {
            if(context.state.types.length === 0) {
                try {
                    const types = (await axios.get(apiEndpoint+'/types?cacheBust='+(Date.now()).toString())).data.data || [];
                    context.commit('SET_TYPES', types);
                }
                catch (e) {
                    console.log(e);
                    context.commit('SET_TYPES', []);
                }
            }
            return context.state.types;
        },
    },
}
