<template>
    <b-overlay :show="loading">
        <template #overlay>
            <custom-loader/>
        </template>
        <b-row v-if="file !== null && file.hasOwnProperty('id')" :key="'key-'+(fileId !== null ? fileId : '-42')">
            <b-col v-if="(((file.file_type || '').toLowerCase() === 'pdf' && pdfFile !== null) || imgFile !== null) && downloadLink" cols="12">
                <b-button class="float-right" variant="outline-primary" @click="downloadFile">Prenesi datoteko</b-button>
            </b-col>
            <b-col v-if="(file.file_type || '').toLowerCase() === 'pdf' && pdfFile !== null" cols="12">
                <div class="pdf-header">
                    <template v-if="pdfLoading">
                        Nalagam...
                    </template>

                    <template v-else>
                        <span v-if="pdfShowAllPages">{{ pdfPageCount }} stran/i</span>

                        <span v-else>
                            <b-button :disabled="pdfPage <= 1" variant="outline-primary" @click="pdfPage--">❮</b-button>
                            {{ pdfPage }} / {{ pdfPageCount }}
                            <b-button :disabled="pdfPage >= pdfPageCount" variant="outline-primary" @click="pdfPage++">❯</b-button>
                        </span>

                        <b-form-checkbox v-model="pdfShowAllPages" class="float-right" switch>Vse strani</b-form-checkbox>
                    </template>
                </div>

                <div class="pdf-content">
                    <vue-pdf-embed
                        ref="pdfRef"
                        :page="pdfPage"
                        :source="pdfFile"
                        @rendered="handleDocumentRender"
                    />
                </div>
            </b-col>
            <b-col v-else-if="imgFile !== null" cols="12">
                <img :key="'img_'+fileId" ref="fileImg" :src="imgFile"  alt="" style="max-width:90%;"/>
            </b-col>
            <b-col v-else cols="12">
                <span>Neveljavna datoteka</span>
            </b-col>
        </b-row>
    </b-overlay>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';

export default {
    components: {VuePdfEmbed},

    props: {
        file: {
            type: Object,
            default: null,
        },
        downloadLink: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            loading: true,
            fileId: null,

            pdfLoading: true,
            pdfFile: null,
            pdfPage: null,
            pdfPageCount: 1,
            pdfShowAllPages: true,

            imgFile: null,
        };
    },
    async mounted() {
        this.loading = true;

        if (this.file !== null && this.file.hasOwnProperty('id')) {
            try {
                const response = await this.$http.get('/auth/files/' + this.file.id, {responseType: 'blob'});
                this.fileId = this.file.id;

                if ((this.file.file_type || '').toLowerCase() === 'pdf') {
                    this.pdfFile = window.URL.createObjectURL(response.data);
                }
                else {
                    this.imgFile = window.URL.createObjectURL(response.data);
                }
            }
            catch (e) {
                console.log(e);
            }
        }
        this.loading = false;
    },
    methods: {
        handleDocumentRender() {
            this.pdfPageCount = this.$refs.pdfRef.pageCount;
            this.pdfLoading = false;
        },
        downloadFile() {
            //TODO refactor
            if(this.file !== null && this.pdfFile !== null) {
                let link = document.createElement('a');
                link.href = this.pdfFile;
                link.setAttribute('download', this.file.name);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            else if(this.file !== null && this.imgFile !== null) {
                let link = document.createElement('a');
                link.href = this.imgFile;
                link.setAttribute('download', this.file.name);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            else {
                alert('Ne najdem primerne datoteke.');
            }
        },
    },
    watch: {
        pdfShowAllPages() {
            this.pdfPage = this.pdfShowAllPages ? null : 1
        },
    },
};
</script>

<style scoped>
.pdf-header {
    padding: 16px;
    box-shadow: #b4b7bd;
    background-color: #b4b7bd;
    color: #383636;
}
.pdf-content {
    padding: 24px 16px;
}
</style>
