import axios from '@/libs/axios';

export default {
    namespaced: true,
    state: {
        firstname: localStorage.getItem('firstname') || null,
        lastname: localStorage.getItem('lastname') || null,
        email: localStorage.getItem('email') || null,
        role: localStorage.getItem('role') || null,
        clubId: null,
        myInfo: null,
        isSuperAdmin: null,
        storageKey: null,
    },
    getters: {
        getFirstname(state) {
            return state.firstname;
        },
        getLastname(state) {
            return state.lastname;
        },
        getEmail(state) {
            return state.email;
        },
        getRole(state) {
            return state.role;
        },
        async getClubId(state) {
            if(state.clubId === null || state.forceRefresh) {
                try {
                    const club = (await axios.get('/auth/clubs/my')).data.data || null;

                    if(club !== null) {
                        state.clubId = club.id;
                    }
                    else {
                        state.clubId = null;
                    }
                }
                catch(e) {
                    console.log(e);
                    state.clubId = null;
                }
            }
            return state.clubId;
        },
        async isSuperAdmin(state) {
            if(state.isSuperAdmin === null || state.forceRefresh) {
                try {
                    state.isSuperAdmin  = (await axios.get('/auth/me')).data.isSuperAdmin || false;
                }
                catch(e) {
                    console.log(e);
                    state.isSuperAdmin = null;
                }
            }
            return state.isSuperAdmin || false;
        },
    },
    mutations: {
        SET_USER_INFO(state, token) {
            if (state.storageKey !== token.sub) {
                state.firstname = null;
                state.lastname = null;
                state.email = null;
                state.role = null;
                state.clubId = null;
                state.myInfo = null;
                state.isSuperAdmin = null;

                state.storageKey = token.sub;
            }

            state.firstname = token.fnm || null;
            state.lastname = token.lnm || null;
            state.email = token.eml || null;
            state.role = token.rle || null;

            if(state.firstname) localStorage.setItem('firstname', state.firstname);
            if(state.lastname) localStorage.setItem('lastname', state.lastname);
            if(state.email) localStorage.setItem('email', state.email);
            if(state.role) localStorage.setItem('role', state.role);
        },
        DELETE_USER_INFO(state) {
            state.firstname = null;
            state.lastname = null;
            state.email = null;
            state.role = null;
            state.clubId = null;
            state.myInfo = null;
            state.isSuperAdmin = null;

            localStorage.removeItem('firstname');
            localStorage.removeItem('lastname');
            localStorage.removeItem('email');
            localStorage.removeItem('role');
        },
        SET_MY_INFO(state, myInfo) {
            state.myInfo = myInfo || null;
        },
    },
    actions: {
        async getMyInfo(context) {
            if(context.state.myInfo === null) {
                try {
                    const myInfo = (await axios.get('/auth/me')).data;
                    context.commit('SET_MY_INFO', myInfo);
                }
                catch (e) {
                    console.log(e);
                    context.commit('SET_MY_INFO', null);
                }
            }

            return context.state.myInfo;
        },
    },
}
