import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import baseTable from './base-table';
import verticalMenu from './vertical-menu'
import modules from '~/store';

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        app,
        appConfig,
        baseTable,
        verticalMenu,
        ...modules,
    },
    strict: process.env.DEV,
})
