import _get from 'lodash/get';

function objectValueByPath(object, path) {
    return _get(object, path, null);
}

function objectRemoveNullAttr(object) {
    return Object.entries(object)
        .filter(([_, v]) => (v||null) !== null)
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
}

export {objectValueByPath, objectRemoveNullAttr};
