import axios from '@/libs/axios';

const apiEndpoint = '/auth/seasons';

export default {
    namespaced: true,
    state: {
        seasons: [],
    },
    getters: {},
    mutations: {
        SET_SEASONS(state, seasons) {
            state.seasons = seasons;
        },
    },
    actions: {
        async getAll (context) {
            if(context.state.seasons.length === 0) {
                try {
                    const seasons = (await axios.get(apiEndpoint+'?cacheBust='+(Date.now()).toString())).data.data;
                    context.commit('SET_SEASONS', seasons);
                }
                catch (e) {
                    console.log(e);
                    context.commit('SET_SEASONS', []);
                }
            }
            return context.state.seasons;
        },
        async getActive (context) {
            const seasons = await context.dispatch('getAll');
            return seasons.find(s => s.active === 1 || s.active === '1') || null;
        },
    },
}
