import Vue from 'vue';
import axios from 'axios';
import axiosConfig from '~/libs/axiosConfig';
import { getVersion } from '@/owms/versionManager';

const axiosIns = axios.create({
    //TODO You can add your headers here
    // ================================
    // timeout: 1000,
    ...axiosConfig,
    headers: {
        'Accept': 'application/json',
        //TODO cors
        'Access-Control-Allow-Origin': '*',
        'Fe-Owms-Core-Version': getVersion(),
    }
});

Vue.prototype.$http = axiosIns;

export default axiosIns;
