import commons from './commons';
import competitions from './competitions';
import competitionParts from './competitionParts';
import clubs from './clubs';
import games from  './games';
import players from './players';
import referees from '~/store/referees';
import seasons from './seasons';
import sportHalls from './sportHalls';
import teams from './teams';
import user from './user';

const modules = {
    commons,
    competitions,
    competitionParts,
    clubs,
    games,
    players,
    referees,
    seasons,
    sportHalls,
    teams,
    user,
};

export default modules;
