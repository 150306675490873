export const Subjects = {
    all: 'all',

    AdminScope: 'AdminScope',
    ClubScope: 'ClubScope',
    RefereeScope: 'RefereeScope',

    Auth: 'Auth',
    Club: 'Club',
    Competition: 'Competition',
    Export: 'Export',
    Game: 'Game',
    Guest: 'Guest',
    Jersey: 'Jersey',
    MyClub: 'MyClub',
    MyTeam: 'MyTeam',
    PlayerTeam: 'PlayerTeam',
    Registration: 'Registration',
    RegistrationComity: 'RegistrationComity',
    Team: 'Team',
    Tournament: 'Tournament',
    User: 'User',
}
