import axios from '@/libs/axios';

const apiEndpoint = '/auth/games';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        async getById ({}, {id}) {
            try {
                return (await axios.get(apiEndpoint+'/'+id)).data.data || null;
            }
            catch (e) {
                console.log(e);
                return null;
            }
        },
        async getPlayers ({}, {id, teamId}) {
            try {
                return (await axios.get(apiEndpoint+'/'+id+'/players/'+teamId)).data.data || [];
            }
            catch (e) {
                console.log(e);
                return [];
            }
        },
        async getReferees ({}, {id}) {
            try {
                return (await axios.get(apiEndpoint+'/'+id+'/referees')).data.data || null;
            }
            catch (e) {
                console.log(e);
                return null;
            }
        },
        async getReport ({}, {gameId}) {
            try {
                return (await axios.get(apiEndpoint+'/'+gameId+'/game-reports')).data.data || null;
            }
            catch (e) {
                console.log(e);
                return null;
            }
        },
        async saveReport ({}, {gameId,properties}) {
            try {
                return (await axios.post(apiEndpoint+'/'+gameId+'/game-reports/save',
                        {properties}
                )).data.data;
            }
            catch (e) {
                console.log(e);
                return null;
            }
        },
        async addReferee ({}, {id, refereeId, refereeType}) {
            try {
                return (await axios.post(apiEndpoint+'/'+id+'/referees', {
                    id: refereeId,
                    type: refereeType,
                })).data.data || null;
            }
            catch (e) {
                console.log(e);
                return null;
            }
        },
        async removeReferee ({}, {id, refereeType}) {
            try {
                return (await axios.delete(apiEndpoint+'/'+id+'/referees/'+refereeType)).data.data || null;
            }
            catch (e) {
                console.log(e);
                return null;
            }
        },
        async getScore ({}, {id}) {
            try {
                return (await axios.get(apiEndpoint+'/'+id+'/score')).data.data || null;
            }
            catch (e) {
                console.log(e);
                return null;
            }
        },
    },
}
