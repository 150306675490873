import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from '~/routes';

import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn } from '@/auth/utils'
import { versioningTimer } from '@/owms/versionManager';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0};
    },
    routes: routes,
});

router.beforeEach((to, from, next) => {
    versioningTimer.runTimersCheck();

    const isLoggedIn = isUserLoggedIn();
    if(!canNavigate(to, router.app.$ability || null)) {
        if (!isLoggedIn) return next({name: 'login'});

        //try to avoid too much recursion
        let homeRoute = routes.find(route => route.name === 'home');
        homeRoute.matched = routes.filter(route => route.name === 'home');
        if(typeof homeRoute === 'undefined' || !canNavigate(homeRoute, router.app.$ability || null)) return next({name: 'error-404'});

        return next({name: 'home'});
    }

    if(to.meta.redirectIfLoggedIn && isLoggedIn) {
        return next({ name: 'home' });
    }

    return next();
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg');
    if (appLoading) {
        appLoading.style.display = 'none';
    }
});

export default router;
