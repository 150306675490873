import axios from '@/libs/axios';

const Resource = {
    countries : 'countries',
    genders : 'genders',
    leagues : 'leagues',
    registrationTypes : 'registrationTypes',
    registrationTaxes : 'registrationTaxes',
    ageCategories : 'ageCategories',
    ageCategoryGroups : 'ageCategoryGroups',
    playerStatuses: 'playerStatuses',
    roles: 'roles',
    seasons: 'seasons',
    zips: 'zips',
    dos: 'dos',
}

async function getResource(state, r) {
    if(state[r].length === 0) {
        try {
            const resourceData = (await axios.get('/auth/commons/'+r)).data || null;

            if(resourceData !== null) {
                state[r] = resourceData;
                sessionStorage.setItem(r, JSON.stringify(state[r]));
            }
            else {
                state[r] = [];
                sessionStorage.removeItem(r);
            }
        }
        catch(e) {
            console.log(e);
            state[r] = [];
            sessionStorage.removeItem(r);
        }
    }
    return state[r];
}

export default {
    namespaced: true,
    state: {
        [Resource.countries]: JSON.parse(sessionStorage.getItem(Resource.countries)) || [],
        [Resource.genders]: JSON.parse(sessionStorage.getItem(Resource.genders)) || [],
        [Resource.leagues]: JSON.parse(sessionStorage.getItem(Resource.leagues)) || [],
        [Resource.registrationTypes]: JSON.parse(sessionStorage.getItem(Resource.registrationTypes)) || [],
        [Resource.registrationTaxes]: JSON.parse(sessionStorage.getItem(Resource.registrationTaxes)) || [],
        [Resource.ageCategories]: JSON.parse(sessionStorage.getItem(Resource.ageCategories)) || [],
        [Resource.ageCategoryGroups]: JSON.parse(sessionStorage.getItem(Resource.ageCategoryGroups)) || [],
        [Resource.playerStatuses]: JSON.parse(sessionStorage.getItem(Resource.playerStatuses)) || [],
        [Resource.roles]: JSON.parse(sessionStorage.getItem(Resource.roles)) || [],
        [Resource.seasons]: JSON.parse(sessionStorage.getItem(Resource.seasons)) || [],
        [Resource.zips]: JSON.parse(sessionStorage.getItem(Resource.zips)) || [],
        [Resource.dos]: JSON.parse(sessionStorage.getItem(Resource.dos)) || [],
    },
    getters: {
        async countries(state) {
            return await getResource(state, Resource.countries);
        },
        async genders(state) {
            return await getResource(state, Resource.genders);
        },
        async leagues(state) {
            return await getResource(state, Resource.leagues);
        },
        async registrationTypes(state) {
            return await getResource(state, Resource.registrationTypes);
        },
        async registrationTaxes(state) {
            return await getResource(state, Resource.registrationTaxes);
        },
        async ageCategories(state) {
            return await getResource(state, Resource.ageCategories);
        },
        async ageCategoryGroups(state) {
            return await getResource(state, Resource.ageCategoryGroups);
        },
        async playerStatuses(state) {
            return await getResource(state, Resource.playerStatuses);
        },
        async roles(state) {
            return await getResource(state, Resource.roles);
        },
        async seasons(state) {
            return await getResource(state, Resource.seasons);
        },
        async zips(state) {
            return await getResource(state, Resource.zips);
        },
        async dos(state) {
            return await getResource(state, Resource.dos);
        },
    },
    mutations: {
    },
    actions: {
    },
}
