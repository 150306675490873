<template>
    <div>
        <b-row>
            <b-col cols="12">
                <cropper
                    ref="cropper"
                    class="cropper"
                    :src="image"
                    :stencil-props="{ aspectRatio: aspectRatioProp, scalable: true }"
                    />
            </b-col>
            <b-col v-if="canUpload" cols="12">
                <b-button variant="outline-primary" @click="$refs.file.click()" class="float-right">Naloži sliko</b-button>
            </b-col>
        </b-row>
        <input type="file" ref="file" @change="uploadImage($event)" accept="image/jpeg, image/png" hidden>
    </div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper';

export default {
    components: {
        Cropper
    },
    props: {
        aspectRatioProp: {
            type: Number,
            default: 1/1.285,
        },
        canUpload: {
            type: Boolean,
            default: true,
        },
        defaultFile: {
            default: null,
        },
    },
    data() {
        return {
            image: null,
        };
    },
    async mounted() {
        if (this.defaultFile !== null && this.defaultFile.hasOwnProperty('id')) {
            try {
                const response = await this.$http.get('/auth/files/' + this.defaultFile.id, {responseType: 'blob'});
                this.image = window.URL.createObjectURL(response.data);
            }
            catch (e) {
                console.log(e);
            }
        }
    },
    methods: {
        getImage() {
            try {
                const {coordinates, canvas} = this.$refs.cropper.getResult();
                if(typeof canvas != 'undefined' && canvas != null) {
                    return canvas.toDataURL();
                }
                return null;
            }
            catch (e) {
                console.log(e);
                return null;
            }
        },
        uploadImage(event) {
            const input = event.target;
            if (input.files && input.files[0]) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    console.log(e);
                    this.image = e.target.result;
                }
                reader.readAsDataURL(input.files[0]);
            }
        },
    },
}
</script>

<style scoped>
.cropper {
    height: 300px;
    width: 100%;
    background: #DDD;
}
/*.cropper {
    border: solid 1px #EEE;
    height: 300px;
    width: 100%;
}

.button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 17px;
}

.button {
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    background: #3fb37f;
    cursor: pointer;
    transition: background 0.5s;
}

.button:hover {
    background: #38d890;
}

.button input {
    display: none;
}*/
</style>
